import React, { useState } from 'react'
import Header from './header'
import Projects from './projects'
import Skills from './skills'
import Node from '../assets/images/node.png'
import Tailwind from '../assets/images/tailwind.png'
import Bootstrap from '../assets/images/bootstrap.png'
import Mongo from '../assets/images/mongo.png'
import Reactlogo from '../assets/images/react.png'
import Leaflet from '../assets/images/leaflet.png'
import priyanshp from "../assets/images/priyanshp.png"
import newsa from "../assets/images/newsa.jpg"
import geo from "../assets/images/geomap.png"
import manager from "../assets/images/mymgr.png"
import ems from "../assets/images/employeeManager.png"
import calculator from "../assets/images/calculator.png"
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import EmailIcon from '@mui/icons-material/Email';
import { AiOutlineInstagram } from 'react-icons/ai';
import { CiLinkedin } from 'react-icons/ci';
import { AiOutlineMail } from 'react-icons/ai';
import Modal from './modal'
const Index = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Modal open={open} setOpen={setOpen} />
      <div className="bg-gray-800 p-4 text-white">Neeraj Minhas</div>
      <div class="container mx-auto mt-6 ">
        <Header />
        <div className="grid grid-cols-1 my-12 ">
          <div className="text-center text-pink-600">- - - - - My Skills - - - -</div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 my-5">
          <Skills icon={Reactlogo} width={"w-8/12"} description={""} skillName={"React"} />
          <Skills icon={Tailwind} width={"w-8/12"} description={""} skillName={"Tailwind"} />
          <Skills icon={Bootstrap} width={"w-6/12"} description={""} skillName={"Bootstrap"} />
          <Skills icon={Mongo} width={"w-5/12"} description={""} skillName={"Mongo"} />
          <Skills icon={Node} width={"w-5/12"} description={""} skillName={"Node"} />
          <Skills icon={Leaflet} width={"w-6/12"} description={""} skillName={"Other"} />
        </div>
        <div className="grid grid-cols-1 my-12">
          <div className="text-center  text-pink-600 ">- - - - - My Work - - - -</div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mx-4 md:mx-0">
          
          <Projects crisp={"From backend to Frontend - My First FullStack Application"} setOpen={setOpen} heading={"Employee Manager"} description={"My Full Stack Application - Nov 2022"} image={ems} isLive={false} url="https://dreamy-wisp-7807b6.netlify.app/" />
          <Projects crisp={"Application which generates report based on uploaded map or selected map"} setOpen={setOpen} heading={"Birds Mapping"} description={"Live Project"} image={geo} isLive={false} url="https://dreamy-wisp-7807b6.netlify.app/" />
          <Projects crisp={"Know More"} setOpen={setOpen} heading={"Powerful CRM"} description={"Live Project (A high end CRM)"} image={manager} isLive={false} url="https://dreamy-wisp-7807b6.netlify.app/" />
          <Projects crisp={"While learning Taiwind"} setOpen={setOpen} heading={"Priyansh's Portfolio"} description={"Developed during learning Phase - Aug 2022"} image={priyanshp} isLive={true} url="https://fanciful-hamster-f1e116.netlify.app" />
          <Projects crisp={"While learning React"} setOpen={setOpen} heading={"NewsBuddy App"} description={"Developed During learning Phase - Sep 2022 "} image={newsa} isLive={true} url="https://dreamy-wisp-7807b6.netlify.app/" />
          <Projects crisp={"Calculator for Eligibilty Calculation convenience"} setOpen={setOpen} heading={"Eligibility Calculator"} description={" "} image={calculator} isLive={true} url="https://astonishing-kangaroo-5f8ce0.netlify.app/" />
        </div>
      </div>
      <div className="grid grid-cols-1 my-12">
        <div className="text-center  text-pink-600 ">STILL ADDING .................</div>
      </div>
      <div className="grid grid-cols-3 bg-white p-5 text-gray-500">

        <div></div>
        <div className=" grid grid-cols-5 col-span-3 md:col-span-1 place-items-center mt-6 text-center">
          <p></p>

          <a href="https://www.linkedin.com/in/neeraj-minhas-2a1066193"> <CiLinkedin size={20} /></a>
          <a href="https://www.instagram.com/neerajminhas/"><AiOutlineInstagram size={20} /></a>
          <a href="mailto:minhasneeraj@gmail.com"><AiOutlineMail size={20} /></a>
          <p></p>
        </div>
        <div></div>
        <div></div>
        <div className="col-span-3 mt-8 text-center">
          <p>   Designed and Developed by Neeraj Minhas</p>
          <p>9736231480</p>
          <p>minhasneeraj@gmail.com</p>
        </div>
        <div></div>
      </div>
      <div className="bg-gray-800 p-4"></div>
    </>

  )
}

export default Index