import React from 'react'

import profileimage from "../../assets/images/neeraj.jpg"




const Header = () => {

  const stats = [
    { name: 'Projects', value: '6+' },
    { name: 'Education', value: 'MCA' },
    { name: 'Currently at', value: 'Alphanzo tech Ltd.' },
    { name: 'Experience', value: '8 Months' },
   
  ]

  return (

    <div className="grid  grid-cols-1 lg:grid-cols-3 gap-6 ">
      <div className="grid place-items-center lg:place-items-start"><img className="rounded-full" src={profileimage}></img></div>

      <div className="grid col-span-2   font-light text-center lg:text-left text-gray-600 p-10  " >
        <div className="text-4xl md:text-5xl   mt-0 md:mt-8 ">
          <p className="pt-2 font-medium text-align-left">I am Neeraj Minhas</p>
          <p className="pt-1  text-pink-600">FullStack Developer</p>
        </div>
        <div className=" ">

          <div className="mx-auto mt-10 lg:mt-20  ">
            {/* <div className="mx-auto  max-w-2xl lg:mx-0">

              <p className="  leading-8 text-black-300">
                Started 6 months ago and still coading....
              </p>
            </div> */}
            <div className="mx-auto  max-w-2xl lg:mx-0 lg:max-w-none">

              <dl className=" grid grid-cols-2 gap-8 mt-10 sm:grid-cols-2 text-md md:text-xl lg:grid-cols-4">
                {stats.map((stat) => (
                  <div key={stat.name} className="flex flex-col-reverse">
                    { console.log("Hii")}
                    <dd className=" font-semibold leading-9 tracking-tight text-black">{stat.value}</dd>
                    <dt className=" leading-7 text-gray-700">{stat.name}</dt>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}

export default Header