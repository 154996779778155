import React from 'react';



const Projects = (props) => {

    const { heading, image, url, isLive, setOpen,crisp } = props;
    return (
        <div onClick={()=>isLive&&window.open(url)}>
            <div onClick={() => heading==="Powerful CRM" && setOpen(true)}>
                <div className="grid grid-cols-1 md:grid-cols-2 bg-gray-600 hover:bg-pink-700 shadow-md hover:shadow-xl rounded-xl hover:scale-100 transition duration-300  hover:ease-in">
                    <div className="text-left p-2"><p className=" text-xl font-semibold text-white p-2 ">{heading}</p></div>
                    {isLive && <div className="text-right p-4 flex justify-end"  >

                        <div>
                            <p className=" text-xl font-semibold  text-green-600  ">LIVE</p>
                        </div>
                        <div class="animate-ping h-3 w-3 m-2 rounded-full bg-green-600 opacity-75"></div>
                    </div>}
                    <div className="grid place-items-center col-span-2 relative">
                        <img src={image} alt={heading} />
                        <div className={`group  ${heading==="Powerful CRM"&&"cursor-pointer"} absolute h-[100%] w-[100%] grid place-items-center text-opacity-0  hover:backdrop-blur-md hover:backdrop-brightness-50 hover:text-opacity-100 `}>
                            <div className="invisible group-hover:visible">
                                <span className="block text-xl text-white text-center font-semibold">{crisp===""?"Know More":crisp}</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="p-3 col-span-2 text-gray-200 text-sm ">{description}</div> */}
                </div>

            </div>
        </div>
    );

}

export default Projects