import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {UsersIcon } from '@heroicons/react/24/outline'

export default function Modal({ open, setOpen }) {

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <UsersIcon className="h-8 w-8 text-blue-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-bold leading-6 text-gray-900">
                                                CRM
                                            </Dialog.Title>
                                            <div className="mt-2 text-gray-500">
                                                <p className="text-base">

                                                    <span className="font-medium">About-</span> I have been deeply involved in the development of an incredibly robust CRM, pouring my efforts into its creation and continuing to do so. This project has been an integral part of my React journey, occupying more than 60 percent of my overall time dedicated to mastering the framework. Throughout its development, I have actively collaborated with developers from diverse countries, each bringing their unique skill set to the table. The constant communication and exchange of ideas have played a vital role in driving this project towards success.
                                                </p>
                                                <p className="mt-2 font-medium">Working Areas (BACKEND/FRONTEND)</p>
                                                <p className="mt-2"><span className="font-medium">Goals-</span>
                                                    The Goals section within the CRM platform offers users a diverse range of options to create and track their habits and goals. With a highly interactive user interface, users can record habits, visually monitor progress, and customize objectives based on their unique requirements. The intuitive interface and comprehensive tracking capabilities facilitate effective management of habits and goals, optimizing productivity and achieving desired outcomes.</p>
                                                <p className="mt-2">
                                                    <span className="font-medium">COURSES-</span>
                                                    In this platform, the Courses section enables users to create engaging courses with lessons, videos, and quizzes. Instructors can easily structure content, incorporate youtube videos . Learners enjoy a visually appealing interface, facilitating easy navigation and immersive learning. This comprehensive system empowers instructors and students alike to create and engage with educational or any other content effectively.

                                                </p>

                                                <p className="mt-2">
                                                    <span className="font-medium">Other Areas-</span>

                                                    In addition to the Goals and Courses sections, I actively explored and learned from various other areas of the CRM platform. This continuous learning journey allowed me to acquire valuable skills and expertise.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    {/* <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={() => setOpen(false)}
                                    >
                                        Deactivate
                                    </button> */}
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}