import React from 'react'

function Skills(props) {
    const {description,skillName,width,icon}=props;
  return (
    
    <div className="grid grid-cols-3 text-center -center bg-transparent rounded  mx-3 my-2 md:mx-12 ">
    <div className="rounded-l-lg  border-8 border-gray-300  px-3 py-2  grid content-center  "><div className=" grid justify-items-center"><img src={icon} width="80"/></div></div>
    <div className="py-2  grid col-span-2 content-center">
        <div className="bg-gradient-to-r from-white to-slate-200 p-2">
            <div>{skillName}</div>
        </div>
        <div className={`${width} animate-pulse rounded mt-1 bg-gradient-to-r from-green-100 to-green-400 p-2`}/>
            <p className="font-light text-sm text-blue-800">{description}</p>
        </div>
    </div>


  )
}

export default Skills